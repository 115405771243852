<template>
  <div class="container" v-if="!!company">
    <fieldset class="p-8 border border-gray-200 rounded-md shadow-md">
      <h1 class="text-3xl">Informazioni profilo</h1>
      <h4 class="text-gray-600 border-b pb-4 text-sm">
        Informazioni di base associate al profilo
      </h4>
      <!-- <div>
        <label for="">Company id</label>
        <input
          type="text"
          disabled
          :value="company.company_id"
          class="w-full"
        />
      </div> -->
      <table class="w-full mt-8">
        <tr>
          <td class="fieldname">nome</td>
          <td class="fieldvalue">{{ company.name }}</td>
        </tr>
        <!-- <tr>
          <td class="fieldname">cognome</td>
          <td class="fieldvalue"></td>
        </tr> -->
        <tr>
          <td class="fieldname">email</td>
          <td class="fieldvalue">{{ company.email }}</td>
        </tr>
        <tr>
          <td class="fieldname">password</td>
          <td class="fieldvalue">••••••••••</td>
        </tr>
        <tr>
          <td class="fieldname">ragione sociale</td>
          <td class="fieldvalue">{{ company.name }}</td>
        </tr>
        <tr>
          <td class="fieldname">P.iva</td>
          <td class="fieldvalue">{{ company.company_vat_num }}</td>
        </tr>
        <tr v-if="company.company_address">
          <td class="fieldname">Indirizzo</td>
          <td class="fieldvalue">
            {{ company.company_address.street_name }}
            {{ company.company_address.street_number }} -
            {{ company.company_address.city }}
          </td>
        </tr>
        <tr>
          <td class="fieldname">Cellulare</td>
          <td class="fieldvalue">{{ company.company_phone_number }}</td>
        </tr>
      </table>
    </fieldset>
    <fieldset class="p-8 border border-gray-200 rounded-md shadow-md my-4">
      <div class="container flex">
        <div class="flex-grow">
          <h1 class="text-3xl flex-grow">Presentazione</h1>
          <h4 class="text-gray-600 border-b pb-4 text-sm">
            Scrivi una breve presentazione su di te o sulla tua attività.
          </h4>
        </div>
        <button
          class="hover:bg-secondary-500 hover:text-white text-secondary-500 w-10 h-10 flex justify-center items-center rounded-full shadow-lg"
          @click="showPresentationDialog = true"
        >
          <i
            class="mdi mdi-plus text-2xl"
            v-if="presentation_details == ''"
          ></i>
          <i class="mdi mdi-pencil-outline mdi-24px" v-else></i>
        </button>
      </div>
      <p class="mt-8">{{ presentation_details.attribute_value }}</p>
      <q-dialog
        class=""
        @close="showPresentationDialog = false"
        @keydown.esc="showPresentationDialog = false"
        v-if="showPresentationDialog"
      >
        <div class="containe bg-white px-4 pt-4 w-full">
          <h1 class="text-xl">Presentazione</h1>
          <h4 class="text-gray-400 text-sm">
            Scrivi una breve presentazione su di te o sulla tua attività.
          </h4>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            v-model="presentation_details.attribute_value"
            class="w-full border border-gray-700 bg-gray-200 rounded-md mt-4 p-4"
          ></textarea>
          <div class="w-full my-4 flex justify-end">
            <div class="w-1/2 mr-4 text-right">
              <q-button :cta="true" @click="saveText" :loading="isSaving">
                Salva
              </q-button>
            </div>
            <button
              class="py-2 px-8 rounded-md text-red-500 border border-red-500"
              @click="presentation_details = ''"
            >
              Reset
            </button>
          </div>
        </div>
      </q-dialog>
    </fieldset>
    <fieldset class="p-8 border border-gray-200 rounded-md shadow-md my-4">
      <div class="container flex">
        <div class="flex-grow">
          <h1 class="text-3xl">Altro</h1>
          <h4 class="text-gray-600 border-b pb-4 text-sm">
            Dicci altro riguardo la tua attività
          </h4>
        </div>
        <button
          class="bg-secondary-500 w-10 h-10 text-white flex justify-center items-center rounded-full shadow-lg"
          @click="showOtherDialog = true"
        >
          <i class="mdi mdi-plus text-2xl"></i>
        </button>
      </div>
      <p class="mt-8">{{ other_details.attribute_value }}</p>
      <q-dialog
        class=""
        @close="showOtherDialog = false"
        @keydown.esc="showOtherDialog = false"
        v-if="showOtherDialog"
      >
        <div class="containe bg-white px-4 pt-4 w-full">
          <h1 class="text-xl">Altro</h1>
          <h4 class="text-gray-400 text-sm">
            Scrivi una breve altro su di te o sulla tua attività.
          </h4>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            v-model="other_details.attribute_value"
            class="w-full border border-gray-700 bg-gray-200 rounded-md mt-4 p-4"
          ></textarea>
          <div class="w-full my-4 flex justify-end">
            <div class="w-1/2 mr-4 text-right">
              <q-button :cta="true" @click="saveText" :loading="isSaving">
                Salva
              </q-button>
            </div>
            <button
              class="py-2 px-8 rounded-md text-red-500 border border-red-500"
              @click="other_details = ''"
            >
              Reset
            </button>
          </div>
        </div>
      </q-dialog>
    </fieldset>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import QDialog from "@/_components/QDialog";
import { API_URL } from "@/app-axios";

// import { API_URL } from "../../app-axios.js";
// import { Auth } from "aws-amplify";

// const api_path = "/companies/ids";

export default {
  data() {
    return {
      showPresentationDialog: false,
      showOtherDialog: false,
      isSaving: false,
      details: {
        presentationText: "",
        otherText: ""
      }
    };
  },
  components: {
    QDialog
  },
  mounted() {
    // API_URL({ url: `${api_path}/${this.companyId}` }).then(s => {
    //   this.company = s.data;
    // });
  },

  computed: {
    ...mapGetters("auth", ["currentUserData"]),
    ...mapGetters("products", ["companyId"]),
    ...mapState("createstore", ["company"]),
    presentation_details: {
      get() {
        let pt =
          this.company.company_details.filter(
            cd => cd.attribute_name === "presentazione"
          )[0] || "";
        return pt;
      },
      set(v) {
        v = { name: "presentazione", value: v };
        this.SET_PRESENTATION(v);
      }
    },
    other_details: {
      get() {
        let pt =
          this.company.company_details.filter(
            cd => cd.attribute_name === "altro"
          )[0] || "";

        return pt;
      },
      set(v) {
        v = { name: "altro", value: v };
        this.SET_OTHER(v);
      }
    }
  },
  methods: {
    ...mapMutations("createstore", ["SET_PRESENTATION", "SET_OTHER"]),
    saveText() {
      try {
        // let company_details = [];

        // let presentation = {
        //   attribute_name: "presentazione",
        //   attribute_value: this.presentation_details,
        // };
        // let other = {
        //   attribute_name: "altro",
        //   attribute_value: this.other_details,
        // };

        // if (this.company.company_details) {
        // company_details = this.company.company_details;
        // const currentPresentation = company_details.find(
        //   (attr) => attr.attribute_name === "presentazione"
        // );
        // if (this.showPresentationDialog) {
        //   const currentPrIndex = company_details.indexOf();
        //   company_details.splice(currentPrIndex, 1);
        // }

        // const currentOther = company_details.find(
        //   (attr) => attr.attribute_name === "altro"
        // );
        // if (currentOther && this.showOtherDialog) {
        //   const currentOthIndex = company_details.indexOf(currentOther);
        //   company_details.splice(currentOthIndex, 1);
        // }
        // } else {
        //   this.company.company_details = company_details;
        // }
        // this.showPresentationDialog && company_details.push(presentation);
        // this.showOtherDialog && company_details.push(other);
        this.updateCompany();
      } catch (error) {
        console.log("Errore", error);
      } finally {
        this.showPresentationDialog = false;
        this.showOtherDialog = false;
      }
    },
    updateCompany() {
      API_URL({
        url: `/companies/${this.companyId}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "aws-access-token": this.$store.getters["auth/token"]
        },
        data: this.company
      })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="postcss">
.fieldname {
  @apply text-sm font-light tracking-wider text-gray-700 uppercase py-2 w-48;
}
</style>
