<template>
  <div
    class="w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center dialog_container "
    @click.stop.self="$emit('close')"
  >
    <dialog
      class=" dialog"
      :class="big ? 'fixed top-0 left-0' : 'relative'"
      @click.stop="() => false"
    >
      <slot />
    </dialog>
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="postcss">
.dialog {
  @apply flex justify-center items-center rounded-md  z-50 shadow-md w-3/4;
}
.dialog_container {
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
}
</style>
